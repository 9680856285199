import * as React from "react"
import Layout from "../components/layout"
import { Container, Row, Col} from "react-bootstrap"
import { Helmet } from 'react-helmet';

// markup
const ConsultingPage = () => {

  return (
    <Layout>
      <Helmet>
        <title>Jobs / Karriere - Unternehmensgruppe Urbas</title>
      </Helmet>
      <Container>
        <Row>
          <Col md={12} className="overflow-hidden" style={{height: '25vh'}}>
            <div className="bg-dark-primary parallelogram-header1-sub">
              <div className="unskew-header1-sub">
                <div id="rect-sub">
                  <div className="p-3">
                    <h1>
                      Jobs / Karriere
                      <br />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="pt-3 pb-5 p-0 ">
        <div className="bg-ultralight m-1">
          <div className="container-fluid py-4 text-left" style={{maxWidth: 1000}}>
            <Row className="pb-2">
              <Col>
                <h4>Aktuelle Stellenangebote:</h4>
                <div className="p-2">
                  <p className="font-primary">
                    - Derzeit keine Stellenausschreibung
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Motivation</h4>
                <div className="p-2">
                  <p>
                    Die Kompetenz, Begeisterung, Kreativität und nicht zuletzt die Zufriedenheit
                    unserer Mitarbeiter sind unser Kapital. Ohne sie würden wir heute nicht da stehen wo wir sind.
                  </p>
                  <br></br>
                  <p>
                    Wir setzen auf Menschen, die sich aktiv in ein Team einbringen, die innovationsfähig sind, gestalten wollen und Herausforderung als Chance begreifen.
                    Wir bieten Ihnen ein Umfeld, das Ihnen ein Vehikel beruflicher und persönlicher Entwicklung sein kann.
                  </p>
                  <br></br>
                  <p>Fühlen Sie sich angesprochen? Dann sollten Sie sich mit uns in Verbindung setzen.</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ConsultingPage
